import './OnlineScreenComponent.css';
import Flag from 'react-world-flags'
import {useEffect, useState} from "react";
import {countries} from 'country-data';

const OnlineScreenComponent = ({users, title}) => {

    const [onLineUsersComponent, setOnLineUsersComponent] = useState([]);

    useEffect(() => {
        setValues()
    }, [users]);

    const setValues = () => {
        let groupedByNationality = users.reduce((acc, obj) => {
            const nationality = obj.nationality;
            if (!acc[nationality]) {
                acc[nationality] = [];
            }
            acc[nationality].push(obj);
            return acc;
        }, {});
        const entries = Object.entries(groupedByNationality);
        entries.sort((a, b) => b[1].length - a[1].length);
        const sortedObj = Object.fromEntries(entries);
        const nationalityParagraphs = Object.entries(sortedObj).map(([nationality, nationalityArray]) => {
            return <div className={"OnlineScreenComponent-rowContainer"}>
            <div className={"OnlineScreenComponent-row"}>
                <div className={"OnlineScreenComponent-flagContainer"}>
                    <Flag code={nationality} height="20" width="60"/>
                    <p className={"OnlineScreenComponent-text OnlineScreenComponent-flagText"}>
                        {countries[nationality].name}</p>
                </div>
                <p className={"OnlineScreenComponent-text OnlineScreenComponent-positive"}>
                    {nationalityArray.length}</p>
            </div>
                <div className={"OnlineScreenComponent-separator"} />
            </div>
        });
        setOnLineUsersComponent(nationalityParagraphs)
    }

    return (
        <div className={"OnlineScreenComponent-container"}>
            <p className={"OnlineScreenComponent-title"}>{title}</p>
            <div className={"OnlineScreenComponent-separator"} />
                {onLineUsersComponent}
        </div>
    );
};

export default OnlineScreenComponent;
