import initialState from './initialState';
import * as ActionTypes from '../Actions/ActionTypes';
import produce from "immer";

const authReducer = (state = initialState.login, action) =>
    produce(state, draft => {
        switch(action.type) {
            case ActionTypes.AUTH_REQUEST: {
                draft.error = null;
                draft.loading = true;
                return;
            }
            case ActionTypes.AUTH_SUCCESS: {
                draft.loading = false;
                const username = action.payload.username;
                const password = action.payload.password;
                draft.username = username;
                draft.password = password;

                return;
            }
            case ActionTypes.AUTH_FAILURE: {
                draft.error = action.payload;
                draft.loading = false;
                return;
            }

            default: {return state;}
        }
    })

export default authReducer;
