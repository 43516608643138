import './App.css';
import React, {Component, useState} from 'react';
import {HomePage} from "./Screens/HomePage";

function setToken(userToken) {
    sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
}

class App extends Component {
    render() {
        return (
            <HomePage/>
        );
    }
}

export default App;




