import React, {useEffect, useState} from "react";
import './HomePage.css';
import TopBar from "../Components/topBar";
import {Login} from "../Components/Login/Login";
import {useDispatch, useSelector} from "react-redux";
import {DashboardComponent} from "../Components/DashboardComponent";
import {authRequest} from "../Store/Actions";
import Modal from "../Components/Modal";

export const HomePage = () => {
    const [loading, setLoading] = useState(false);
    const [isAlreadySigIn, setIsAlreadySigIn] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const stateUsername = useSelector(state => state.auth.username);
    const dispatch = useDispatch();

    const loginError = useSelector(state => state.auth.error);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setTimeout(() => {
                dispatch(authRequest("cavech", "Virusss84!"));
                setIsAlreadySigIn(true);
            }, 500);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const hideConversionModal = () => {
        setIsShowModal(false);
    };

    useEffect(() => {
        console.error('the login error is: ', loginError)
        if (loginError) {
            setIsShowModal(true)
        }
    }, [loginError]);


    return(
        <div className={stateUsername ? "Home containerAuto" : "Home container"}>
            <div className={"Home header"}>
                <TopBar title={stateUsername ? "Dashboard" : "App Analytics login"}/>
            </div>
            <div className="Home content">
                {stateUsername && isAlreadySigIn ?
                    <DashboardComponent /> :
                    <Login loading={loading}
                           setLoading={setLoading}
                           setIsShowModal={setIsShowModal}
                           isMobile={isMobile}/>}
            </div>
            <div className="Home footer">
                <p className="Home FooterText">App Analystics by Marco Cavicchi all rights reserved</p>
            </div>
            <Modal show={isShowModal} handleClose={hideConversionModal}>
                <div className={"Home errorContainer"}>
                    <p className={"Home loginErrorTitle"}>Errore di autenticazione</p>
                    <p className={"Home loginErrorText"}>Ops c'è stato un errore durante il processo di login... Ti preghiamo di riprovare usando le credenziali corrette</p>
                </div>
            </Modal>
        </div>
    )
}
