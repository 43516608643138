import {
    load24HoursUsers,
    loadDailyConversion,
    loadMonthleyUsers,
    loadOnLineUsers
} from "../Store/Actions";
import {useDispatch, useSelector} from "react-redux";
import "./DashboardComponent.css";
import AppSwitch from "../Dashboard/AppSwitch";
import UsersContainer from "../Dashboard/UsersContainer";
import {Spinner} from "react-activity";
import ChartComponent from "../Dashboard/ChartComponent";
import React, {useEffect, useRef, useState} from "react";
import Modal from "../Components/Modal";
import ConversionScreenComponent from "./ConversionScreenComponent";
import OnlineScreenComponent from "./OnlineScreenComponent";

export const DashboardComponent = ({ }) => {
    const [app, setApp] = useState("all");
    const [hoursChartLabel, setHoursChartLabel] = useState([]);
    const [hoursChartDataSet, setHoursChartDataSet] = useState([]);
    const [hoursNewUsersChartDataSet, setHoursNewUsersChartDataSet] = useState([]);
    const [onLineUser, setOnLineUser] = useState(0);
    const [dailyUser, setDailyUser] = useState(0);
    const [isConversionModal, setIsConversionModal] = useState(false);
    const [isOnlineUsersModal, setIsOnlineUsersModal] = useState(false);
    const [isDailyUsersModal, setIsDailyUsersModal] = useState(false);
    const [isAllUsersModal, setIsAllUsersModal] = useState(false);
    //const [conversion, setConversion] = useState(0);

    const onLineUsers = useSelector(state => state.users.onlineUsers);
    const dailyUsers = useSelector(state => state.users.dailyUsers);
    const newDailyUsers = useSelector(state => state.users.newDailyUsers);
    const conversions = useSelector(state => state.logs.conversions);
    const loading = useSelector(state => state.users.loading);

    const loadingConversion = useSelector(state => state.logs.loading);
    const dispatch = useDispatch();

    const [isMobile, setIsMobile] = useState(false);

    useEffect( async () => {
        const a = await dispatch(loadOnLineUsers());
        while (a !== 200) {
            await dispatch(loadOnLineUsers());
            return;
        }
        const b = await dispatch(load24HoursUsers());
        while (b !== 200) {
            await dispatch(load24HoursUsers());
            return;
        }
        const c = await dispatch(loadDailyConversion());
        while (c !== 200) {
            await dispatch(loadDailyConversion());
            return;
        }
        const d = await dispatch(loadMonthleyUsers());
        while (d !== 200) {
            await dispatch(loadMonthleyUsers());
            return;
        }
        const e = await dispatch(loadDailyConversion());
        while (e !== 200) {
            await dispatch(loadDailyConversion());
            return;
        }
        createChartsLabel();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        getOnLineUsers();
        get24HoursUsers();
    }, [hoursChartLabel]);
    /*const prevOnLineUsers = usePrevious(onLineUsers);
    const prevApp = usePrevious(app);*/

    /*useEffect(() => {
        if (
            !isEqual(onLineUsers, prevOnLineUsers) ||
            !isEqual(prevApp, app)
        ) {
            reloadApp();
            console.log('[useEffect]', onLineUsers, prevOnLineUsers)
        }
    }, [onLineUsers]);*/

    /*function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value; //assign the value of ref to the argument
        },[value]); //this code will run when the value of 'value' changes
        return ref.current; //in the end, return the current ref value.
    }*/

    const onChangeAppState = state => {
        setApp(state);
        reloadApp();
    }

    const getOnLineUsers = () => {
        const users = onLineUsers;
        let finalData;
        switch (app) {
            case "all":
                finalData = users;
                break;
            default:
                finalData = users.filter(user => {
                    return user.app === app;
                })
                break;
        }
        setOnLineUser(finalData.length);
    }

    const reloadApp = () => {
        Promise.all([dispatch(loadOnLineUsers()),
            dispatch(load24HoursUsers()),
            dispatch(loadDailyConversion())])
            .then(()=> {
                getOnLineUsers();
                get24HoursUsers();
            })
    }

    const createChartsLabel = () => {
        var currHour = new Date().getHours() + 1;
        let arraFromNow = Array(24-currHour).fill().map((e,i)=>i+currHour+":00")
        var text_hour;
        for (var i = 0; i < 24; i++) {
            if (i > 9) {
                text_hour = i+':00';
            } else {
                text_hour = '0'+i+':00';
            }
            if (arraFromNow.length < 24) {
                arraFromNow.push(text_hour);
            }
        }
        setHoursChartLabel(arraFromNow);
        /*const now = new Date();
        const days = getAllDaysInMonth(now.getFullYear(), now.getMonth());
        setMonthChartLabel(days);*/
    }

    const getAllDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1);

        const dates = [];

        while (date.getMonth() === month) {
            dates.push(new Date(date).toLocaleDateString("it"));
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }

    const get24HoursUsers = async () => {

        let finalData;
        switch (app) {
            case "all":
                finalData = dailyUsers;
                break;
            default:
                finalData = dailyUsers.filter(user => {
                    return user.app === app;
                })
                break;
        }
        setDailyUser(finalData.length);
        //setDailyUsersObjects(dailyUsers);
        await createDataSetHours(dailyUsers);
        await createDataSetHoursNewUsers(newDailyUsers);
    }

    const createDataSetHours = users => {
        const test1 = hoursChartLabel.map(hour => {
            const test = users.filter(user => {
                const firstAccess = new Date(user.firstAccess);
                const lastAccess = new Date(user.lastAccess);
                let hourx = (lastAccess.getHours() + 2).toString();
                const localApp = user.app;
                if (hourx.length === 1) {
                    hourx = "0"+(lastAccess.getHours() + 2).toString()+":00"
                } else {
                    hourx = (lastAccess.getHours() + 2).toString()+":00"
                }
                if (hour === hourx && (localApp === app || app === "all")) {
                    const now = new Date();
                    if (firstAccess.getDay() !== now.getDay() ||
                        firstAccess.getMonth() !== now.getMonth() ||
                        firstAccess.getFullYear() !== now.getFullYear() ||
                        firstAccess.getDay() !== (now.getDay() -1)) {
                        return user;
                    }

                }
            })
            return test.length;
        })
        setHoursChartDataSet(test1);
    }

    const createDataSetHoursNewUsers = users => {
        const test1 = hoursChartLabel.map(hour => {
            const test = users.filter(user => {
                const lastAccess = new Date(user.lastAccess);
                let hourx = (lastAccess.getHours() + 2).toString();
                const appLocal = user.app;
                if (hourx.length === 1) {
                    hourx = "0"+(lastAccess.getHours() + 2).toString()+":00"
                } else {
                    hourx = (lastAccess.getHours() + 2).toString()+":00"
                }
                if (hour === hourx && (appLocal === app || app === "all")) {
                    return user;
                }
            })
            return test.length;
        })
        setHoursNewUsersChartDataSet(test1);
    }

    const lineDataSets= {
        labels: hoursChartLabel,
        datasets: [
            {
                label: 'Utenti',
                data: hoursChartDataSet,
                fill: true,
                borderColor: 'rgba(177,255,156,0.8)',
                backgroundColor: 'rgba(177,255,156, 0.3)',
                tension: 0.1,
            },
            {
                label: 'Nuovi utenti',
                data: hoursNewUsersChartDataSet,
                fill: false,
                borderColor: 'rgba(255, 99, 132, 0.8)',
                backgroundColor: 'rgba(255, 99, 132, 0.3)',
                tension: 0.1,
            },
        ],
    }

    const lineOptions = {
        maintainAspectRatio: false,
        responsive: true,
        height: 200,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Utenti nelle ultime 24 ore',
                font: {weight: 'bold', size: 18},
            },
        },
    }

    const showConversionModal = () => {
        setIsConversionModal(true);
    };

    const hideConversionModal = () => {
        setIsConversionModal(false);
    };

    const showOnlineUsersModal = () => {
        setIsOnlineUsersModal(true);
    };

    const hideOnlineUsersModal = () => {
        setIsOnlineUsersModal(false);
    };

    const showDailyUsersModal = () => {
        setIsDailyUsersModal(true);
    };

    const hideDailyUsersModal = () => {
        setIsDailyUsersModal(false);
    };

    const showAllUsersModal = () => {
        setIsAllUsersModal(true);
    };

    const hideAllUsersModal = () => {
        setIsAllUsersModal(false);
    };

    const desktopComponent = (
            <div className={"Dashboard metricsContainer"}>
                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Utenti on Line"}
                                    loading={loading}
                                    value={onLineUsers.length}
                                    onClick={showOnlineUsersModal}
                                    isEnableb={true}/>
                </div>

                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Conversioni"}
                                    loading={loadingConversion}
                                    value={conversions ? conversions.length : 0}
                                    onClick={showConversionModal}
                                    isEnableb={true}/>
                </div>

                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Nuovi utenti"}
                                    loading={loading}
                                    value={newDailyUsers.length}
                                    onClick={showDailyUsersModal}
                                    isEnableb={true}/>
                </div>

                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Utenti attivi"}
                                    loading={loading}
                                    value={dailyUsers.length}
                                    onClick={showAllUsersModal}
                                    isEnableb={true}/>
                </div>
            </div>
    )

    const mobileComponent = (
        <div className={"Dashboard metricsMobileContainer"}>
            <div className={"Dashboard mobileContainer"}>
                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Utenti on Line"}
                                    loading={loading}
                                    value={onLineUsers.length}
                                    onClick={showOnlineUsersModal}
                                    isEnableb={true}/>
                </div>

                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Conversioni"}
                                    loading={loadingConversion}
                                    value={conversions ? conversions.length : 0}
                                    onClick={showConversionModal}
                                    isEnableb={true}/>
                </div>
            </div>
            <div className={"Dashboard mobileContainer"}>
                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Nuovi utenti"}
                                    loading={loading}
                                    value={newDailyUsers.length}
                                    onClick={showDailyUsersModal}
                                    isEnableb={true}/>
                </div>

                <div className={"Dashboard usersContainer"}>
                    <UsersContainer text={"Utenti attivi"}
                                    loading={loading}
                                    value={dailyUsers.length}
                                    onClick={showAllUsersModal}
                                    isEnableb={true}/>
                </div>
            </div>
        </div>
    )

    return (
        <div className={"Dashboard main"}>
            <AppSwitch onChangeAppState={onChangeAppState}
                       pageState={app}
                       onReload={reloadApp}
                       isMobile={isMobile}
            />
            {isMobile ? mobileComponent : desktopComponent}

            <Modal show={isOnlineUsersModal} handleClose={hideOnlineUsersModal}>
                <OnlineScreenComponent users={onLineUsers} title={"Utenti OnLine"}/>
            </Modal>

            <Modal show={isConversionModal} handleClose={hideConversionModal}>
                <ConversionScreenComponent />
            </Modal>

            <Modal show={isDailyUsersModal} handleClose={hideDailyUsersModal}>
                <OnlineScreenComponent users={newDailyUsers} title={"Nuovi utenti giornalieri"}/>
            </Modal>

            <Modal show={isAllUsersModal} handleClose={hideAllUsersModal}>
                <OnlineScreenComponent users={dailyUsers} title={"Utenti attivi"}/>
            </Modal>
            <div className={"Dashboard chartContainer"}>
                {loading ? <Spinner className="value" size={30} animating={true} color={"#0c59e8"}/>
                    : <ChartComponent data={lineDataSets} options={lineOptions} type={"line"}/>}
            </div>

            {/*<Graph loading={loading} labels={monthChartLabel} />*/}

        </div>
    );
}
