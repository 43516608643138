import {combineReducers} from "redux";
import usersReducer from "./Reducers/UsersReducers";
import logReducers from "./Reducers/LogReducers";
import authReducers from "./Reducers/AuthReducers";

const rootReducer = combineReducers({
    auth: authReducers,
    users: usersReducer,
    logs: logReducers,
})

export default rootReducer;
