import axios from "../../Shared/axiosConfig";
import * as actionTypes from './ActionTypes';

export const loadOnLineUsers =() => {
    return dispatch => {
        dispatch(loadUserRequest());

        return axios.client.get(`${axios.apiPath}/user/getOnlineUsers`)
            .then(response => {
                const users = response.data;
                dispatch(loadUserSuccess(users, "online"));
                return response.status;
            })
            .catch(error => {
                dispatch(loadUserFailure());
                return 500;
                throw error;
            });
    };
}

export const load24HoursUsers =() => {
    return dispatch => {
        dispatch(loadUserRequest());

        return axios.client.get(`${axios.apiPath}/user/get24HoursUsers`)
            .then(response => {
                const users = response.data;
                dispatch(loadUserSuccess(users, "daily"));
                return response.status;
            })
            .catch(error => {
                dispatch(loadUserFailure());
                throw error;
            });
    };
}

export const loadMonthleyUsers =() => {
    return dispatch => {
        dispatch(loadUserRequest());

        return axios.client.get(`${axios.apiPath}/user/getMonthleyUsers`)
            .then(response => {
                const users = response.data;
                dispatch(loadUserSuccess(users, "monthley"));
                return response.status;
            })
            .catch(error => {
                dispatch(loadUserFailure());
                throw error;
            });
    };
}

export const loadUserRequest = () => {
    return {
        type: actionTypes.LOAD_USER_REQUEST
    };
};

export const loadUserSuccess = (user, type) => {
    return {
        type: actionTypes.LOAD_USER_SUCCESS,
        payload: {users: user, type: type}
    };
};

export const loadUserFailure = (error) => {
    return {
        type: actionTypes.LOAD_USER_FAILURE,
        payload: error
    };
};
