import React, {Component} from "react";
import PropTypes from "prop-types";
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

class ChartComponent extends Component {

    componentDidMount() {
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            LineElement,
            PointElement,
            Title,
            Tooltip,
            Legend,
            Filler,
        );
    }

    render() {
        const {data, type, options} = this.props;
        let chart;

        if (type === "line") {

            chart = <Line options={options}
                          id={1}
                          height={300}
                          data={data} />
        } else {
            chart = <Bar options={options}
                         id={2}
                         height={300}
                         data={data} />
        }
        return chart;
    }
}

ChartComponent.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
}

export default ChartComponent;
