import React, {useState} from 'react';
import './Login.css';
import PropTypes from 'prop-types';
import {authRequest} from "../../Store/Actions";
import {connect} from "react-redux";
import { useDispatch } from "react-redux";
import {Spinner} from "react-activity";

async function loginUser(credentials) {
    const username = credentials.userName;
    const password = credentials.password;
}

export const Login = ({ loading, setLoading, isMobile }) => {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const dispatch = useDispatch();

    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        dispatch(authRequest(username, password));
        const isGrantAccess = await loginUser({
            "userName": username,
            "password": password
        });
        setLoading(false);
    }

    return(
        <div className={isMobile ? "FormContainerMobile" : "FormContainer"}>
            <form className="form" onSubmit={handleSubmit}>
                <label>
                    <p className="inputSubTitle">Username</p>
                    <input className="input" type="text" onChange={e => setUserName(e.target.value)}/>
                </label>
                <label>
                    <p className="inputSubTitle">Password</p>
                    <input className="input" type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div className={"Login buttonContainer"}>
                    {loading ?
                        <Spinner className="Login spinner" size={15} animating={true} color={"#0c59e8"}/> :
                        <button className="Button" type="submit">Submit</button>}
                </div>
            </form>
        </div>
    )
}

