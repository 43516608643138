import './ConversionScreenComponent.css';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const ConversionScreenComponent = () => {

    const conversions = useSelector(state => state.logs.conversions);

    const [premium, setPremium] = useState(null);
    const [yearlyPremium, setYearlyPremium] = useState(null);
    const [lifetime, setLifeTime] = useState(null);

    useEffect(() => {
        setValues();
    }, [conversions]);

    const setValues = () => {
        let premium = []
        let yearlyPremium = []
        let lifetime = []

        const stringsLog = conversions.map(log => {
            return log.message;
        })

        stringsLog.forEach(string => {
            if (string === "new premium annuale") {
                yearlyPremium.push(string);
            } else if (string === "new premium lifetime") {
                lifetime.push(string);
            } else if (string === "new premium") {
                premium.push(string)
            }
        });

        const premiumComponent = (
            <div className={"ConversionScreenComponent-row"}>
                <p className={"ConversionScreenComponent-subTitle"}>Premium:</p>
                <p className={premium.length > 0 ?
                    "ConversionScreenComponent-text ConversionScreenComponent-positive" :
                    "ConversionScreenComponent-text ConversionScreenComponent-negative"
                }>{premium.length}</p>
            </div>
        );
        setPremium(premiumComponent);

        const yearlyPremiumComponent = (
            <div className={"ConversionScreenComponent-row"}>
                <p className={"ConversionScreenComponent-subTitle"}>Premium Annuale:</p>
                <p className={yearlyPremium.length > 0 ?
                    "ConversionScreenComponent-text ConversionScreenComponent-positive" :
                    "ConversionScreenComponent-text ConversionScreenComponent-negative"
                }>{yearlyPremium.length}</p>
            </div>
        );
        setYearlyPremium(yearlyPremiumComponent);

        const lifetimeComponent = (
            <div className={"ConversionScreenComponent-row"}>
                <p className={"ConversionScreenComponent-subTitle"}>Lifetime:</p>
                <p className={lifetime.length > 0 ?
                    "ConversionScreenComponent-text ConversionScreenComponent-positive" :
                    "ConversionScreenComponent-text ConversionScreenComponent-negative"
                }>{lifetime.length}</p>
            </div>
        );
        setLifeTime(lifetimeComponent);
    }

    return (
        <div className={"ConversionScreenComponent-container"}>
            <p className={"ConversionScreenComponent-title"}>Conversioni di oggi</p>
            <div className={"ConversionScreenComponent-separator"} />
            {premium && premium}
            <div className={"ConversionScreenComponent-separator"} />
            {yearlyPremium && yearlyPremium}
            <div className={"ConversionScreenComponent-separator"} />
            {lifetime && lifetime}
        </div>
    );
};

export default ConversionScreenComponent;
