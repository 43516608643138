const initialState = {
    login: {
        loading: false,
        userName: null,
        password: null,
        error: null
    },
    users: {
        loading: false,
        error: null,
        onlineUsers: [],
        dailyUsers: [],
        newDailyUsers: [],
        monthleyUsers: [],
        conversions: [],
    },
    logs: {
        loading: false,
        error: null,
        conversions: []
    }
}

export default initialState;
