import initialState from './initialState';
import * as ActionTypes from '../Actions/ActionTypes';
import produce from "immer";

const logReducer = (state = initialState.logs, action) =>
    produce(state, draft => {
        switch(action.type) {
            case ActionTypes.LOAD_LOGS_REQUEST: {
                draft.error = null;
                draft.loading = true;
                return;
            }
            case ActionTypes.LOAD_LOGS_SUCCESS: {
                draft.loading = false;
                const logs = action.payload.logs;
                const type = action.payload.type;

                if (type === "conversion") {
                    const dailyLogs = logs.filter(log => {
                        const date = new Date(log.date);
                        const today = new Date();
                        if (date.getDay() === today.getDay() &&
                            date.getMonth() === today.getMonth() &&
                            date.getFullYear() === today.getFullYear()
                        ) {
                            return log
                        }
                    })
                    draft.conversions = dailyLogs;
                }

                return;
            }
            case ActionTypes.LOAD_LOGS_FAILURE: {
                draft.error = action.payload;
                draft.loading = false;
                return;
            }

            default: return state;
        }
    })

export default logReducer;
