import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import configureStore from "./Store/configureStore";
import {Provider} from "react-redux";

const store = configureStore();

const app = (
        <Provider store={store}>
                {<App />}
        </Provider>
);

store.subscribe(() =>
    new Date()
)

ReactDOM.render(app, window.document.getElementById('App'));
