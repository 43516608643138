import initialState from './initialState';
import * as ActionTypes from '../Actions/ActionTypes';
import produce from "immer";

const userReducer = (state = initialState.users, action) =>
    produce(state, draft => {
        switch(action.type) {
            case ActionTypes.LOAD_USER_REQUEST: {
                draft.error = null;
                draft.loading = true;
                return;
            }
            case ActionTypes.LOAD_USER_SUCCESS: {
                draft.loading = false;
                const users = action.payload.users;
                const type = action.payload.type;
                if (type === "online") {
                    draft.onlineUsers = users;
                } else if (type === "daily") {
                    const newUsers = users.filter(user => {
                        const firstAccess = new Date(user.firstAccess);
                        const today = new Date();
                        if (firstAccess.getDay() === today.getDay() &&
                            firstAccess.getMonth() === today.getMonth() &&
                            firstAccess.getFullYear() === today.getFullYear()
                        ) {
                            return user
                        }
                    })
                    draft.dailyUsers = users;
                    draft.newDailyUsers = newUsers;
                } else if (type === "monthley") {
                    draft.monthleyUsers = users;
                }

                return;
            }
            case ActionTypes.LOAD_USER_FAILURE: {
                draft.error = action.payload;
                draft.loading = false;
                return;
            }

            default: return state;
        }
    })

export default userReducer;
