import React, {Component} from 'react';
import "./UserContainer.css";
import PropTypes from "prop-types";
import {Spinner} from "react-activity";
import "react-activity/dist/library.css";

class UsersContainer extends Component {

    render() {
        const {text, value, loading, isEnableb, onClick} = this.props;
        return (
            <button className={"container"}
                    disabled={!isEnableb}
                    onMouseDown={onClick}>
                {loading ? <Spinner className="value" size={30} animating={true} color={"#0c59e8"}/> :
                    <>
                    <text className="value">{value}</text>
                    <text className="description">{text}</text>
                    </>
                }
            </button>
        )
    }
}

UsersContainer.propTypes = {
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    isEnableb: PropTypes.bool.isRequired,
    onClick: PropTypes.func
};

export default UsersContainer;
