import axios from "../../Shared/axiosConfig";
import * as actionTypes from "./ActionTypes";

export const authRequest = (username, password) => {
    return dispatch => {
        dispatch(loginRequest());

        const credentials = {userName: username, password: password}
        return axios.client
            .post(axios.apiPath + '/userAccess/getAccess/', credentials, {
            headers: {'Content-Type': 'application/json'},
        })
            .then(response => {

            if (response.status === 200) {
                const token = response.data;
                localStorage.setItem('token', token)
                dispatch(loginSuccess(username, password));
            } else {
                throw new Error(response.data.exception);
            }
        })
            .catch(error => {
                dispatch(loginFailure(error));
                throw error;
            });
    };
}

const loginRequest = () => {
    return {
        type: actionTypes.AUTH_REQUEST
    };
};

const loginSuccess = (username, password) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: {username: username, password: password}
    };
};

const loginFailure = (error) => {
    return {
        type: actionTypes.AUTH_FAILURE,
        payload: error
    };
};
