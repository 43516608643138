import React, {Component} from "react";
import './TopBar.css';
import PropTypes from "prop-types";
import ReloadImage from "../Asset/reload.png"

class TopBar extends Component {
    render() {
        const {onReload, isReloadButtonVisible} = this.props;
        return(
            <div className="TopBar container">
            <text className="Title">{this.props.title}</text>
                {isReloadButtonVisible && <button className={"ReloadButton"} onClick={onReload}>
                <img className={"ReloadImage"} src={ReloadImage} width={25} height={25} alt={""}/>
                </button>}
        </div>
        );
    }
}

TopBar.propTypes = {
    onReload: PropTypes.func,
    isReloadButtonVisible: PropTypes.bool
};

export default TopBar;
