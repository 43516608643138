import axios from "../../Shared/axiosConfig";
import * as actionTypes from "./ActionTypes";

export const loadDailyConversion =() => {
    return dispatch => {
        dispatch(loadLogRequest());

        return axios.client.get(`${axios.apiPath}/log/get24HoursPremiumLogs`)
            .then(response => {
                const logs = response.data;
                dispatch(loadLogSuccess(logs, "conversion"));
                return response.status;
            })
            .catch(error => {
                dispatch(loadLogFailure());
                throw error;
            });
    };
}

export const loadLogRequest = () => {
    return {
        type: actionTypes.LOAD_LOGS_REQUEST
    };
};

export const loadLogSuccess = (logs, type) => {
    return {
        type: actionTypes.LOAD_LOGS_SUCCESS,
        payload: {logs: logs, type: type}
    };
};

export const loadLogFailure = (error) => {
    return {
        type: actionTypes.LOAD_LOGS_FAILURE,
        payload: error
    };
};
