export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const GET_ONLINE_USERS = 'GET_ONLINE_USERS';
export const GET_DAILY_CONVERSION = 'GET_DAILY_CONVERSION';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const LOAD_LOGS_REQUEST = 'LOAD_LOGS_REQUEST';
export const LOAD_LOGS_SUCCESS = 'LOAD_LOGS_SUCCESS';
export const LOAD_LOGS_FAILURE = 'LOAD_LOGS_FAILURE';
