import React, {Component} from "react";
import "./AppSwitch.css";
import PropTypes from "prop-types";
import ReloadImage from "../Asset/reload.png";

class AppSwitch extends Component {

    render() {

        const {pageState, onChangeAppState} = this.props;
        return (
            <div className={"AppSwitch containerSwitch"}>
                <div onClick={this.props.onReload} className={"AppSwitch reloadApp"}>
                    <img className={"ReloadImage"} src={ReloadImage} width={25} height={25} alt={""}/>
                </div>
                <div className={this.props.isMobile ? "mobileRectangle" : "rectangle"}>
                    <button className={pageState === "all" ? "AppSwitch buttonActiveStyle" : "AppSwitch buttonDefaultStyle"}
                            onClick={() => onChangeAppState("all")}>Tutto</button>
                    <button className={pageState === "MyHomeBills" ? "AppSwitch buttonActiveStyle" : "AppSwitch buttonDefaultStyle"}
                            onClick={() => onChangeAppState("MyHomeBills")}>My Home Bills</button>
                    <button className={pageState === "ScooterTracker" ? "AppSwitch buttonActiveStyle" : "AppSwitch buttonDefaultStyle"}
                            onClick={() => onChangeAppState("ScooterTracker")}>Scooter Tracker</button>
                </div>
            </div>
        );
    }
}

AppSwitch.propTypes = {
    onChangeAppState: PropTypes.func.isRequired,
    pageState: PropTypes.string.isRequired,
    onReload: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired
};

export default AppSwitch;
