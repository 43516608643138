import axios from 'axios';

/*window.__runtime_configuration = {
    apiEndpoint: window.location.protocol + '//api.' + window.location.host.replace(/www\.|test\./, '')
};*/

const apiPath = 'api';
export const url = process.env.NODE_ENV === 'production'
    ? 'https://vmi870078.contaboserver.net:8443/LogAnalyse/'
    : 'https://vmi870078.contaboserver.net:8443/LogAnalyse/';

const instance = {
    client: axios.create({}),
    apiPath: url + apiPath,
    url,
}

instance.client.interceptors.request.use(async config => {
    /*config.headers = 'Bearer ' + 'hello1234567890';
    const headers =  {
        post: {        // can be common or any other method
            header1: 'test123456789'
        },
        get: {        // can be common or any other method
            header1: 'test123456789'
        }
    }
    config.headers = headers;*/
    return config;
}, error => {
    return Promise.reject(error);
});

export default instance;
